<section>
    <header class="card custom_card">
        <div class="d-flex justify_content_between align_item_center">
            <div class="d-flex justify_content_start gap_10">
                <div>Inline Viewer</div>
                <div>Report</div>
            </div>
            <div class="d-flex justify_content_end gap_10">
                <div>Highlight<mat-icon>help</mat-icon></div>
                <div><input type="checkbox" />XBRL Elements</div>
            </div>
        </div>

        <div class=" content zoom-button">
            <button mat-button (click)="zoomIn()"><mat-icon>add</mat-icon></button>
            <button mat-raised-button (click)="zoomOut()" color="warn"><mat-icon>remove</mat-icon></button>
        </div>
    </header>
    <div class="d-flex gap_20 scroll_card ">
        <div class="zoomable-wrapper width_60 mgn-up-10 left-content" [style.transform]="zoomLevel">
            <div class="card mgn-up-10 zoomable-content card_content" style="height:100vh">

                <div>
                    <div class="text-center company_name">M&P Group 2024</div>
                    <div class="text-center mgn-up-10 company_label">Management and Sustainability Report</div>
                </div>

                <div class="mgn-up-20 content">Contents</div>
                <div class="mainContentHover_custom">
                    <ng-container *ngFor="let topic of templateData;let i = index">
                        <div class="d-flex justify_content_between">
                            <div class="mgn-up-10 topic-name" (click)="scrollToTopic(i)">{{topic['topicName']}}</div>
                            <div>{{i + 1}}</div>
                        </div>
                    </ng-container>

                </div>
                <footer class="mgn-up-20">
                    <div class="footer">
                        This document has been prepared by the Sustain-Suite to illustrates the outline of the digital
                        reporting
                        with examples. It accompanies the Explanatory Note and Basis for Conclusions but it is not part
                        of
                        the
                        Draft ESRS Set 1 XBRL Taxonomy for consultation. © Sustain-Suite 2024 -
                        https://sustain-suite.com/
                    </div>
                </footer>
            </div>

            <ng-container *ngFor="let content of templateData;let i = index">
                <div class="card mgn-up-10 card_content" #contentRefs style="height:100vh">

                    <div class="mainContentHover mainContentHover_custom"
                        [ngClass]="{'hover-effect': currentIndex === i}">
                        <div class="topic-name-content">{{content['topicName']}}</div>

                        <div class="topic-name-main-content">{{content['mainContent']}}</div>
                    </div>

                    <footer class="mgn-up-20">
                        <div class="footer">
                            This document has been prepared by the Sustain-Suite to illustrates the outline of the
                            digital
                            reporting
                            with examples. It accompanies the Explanatory Note and Basis for Conclusions but it is not
                            part
                            of
                            the
                            Draft ESRS Set 1 XBRL Taxonomy for consultation. © Sustain-Suite 2024 -
                            https://sustain-suite.com/
                        </div>
                    </footer>
                </div>

            </ng-container>
        </div>

        <div class="card width_30 mgn-up-20 right-content">
            <div class="d-flex justify_content_between align_item_center">
                <div>{{factLabelFilter}}</div>
                <div>
                    <button mat-button
                        [matMenuTriggerFor]="menu"><mat-icon>settings</mat-icon><mat-icon>keyboard_arrow_down</mat-icon></button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item>English</button>
                    </mat-menu>
                </div>
            </div>
            <div class="d-flex justify_content_between  icon_bg">
                <div class="mgn-up-10 d-flex justify_content_start gap_10 align_item_center">
                    <div class="headline" [ngClass]="{'active': activeIcon === 'outlineUnavailable'}">
                        <mat-icon (click)="actionPerform('outlineUnavailable')">view_headline</mat-icon>
                    </div>
                    <div class="search" [ngClass]="{'active': activeIcon === 'search'}">
                        <mat-icon (click)="toggleSearch();actionPerform('search')">search</mat-icon>
                    </div>
                </div>
                <div class="d-flex gap_10 ">
                    <div class="headline"><mat-icon (click)="previousArrow()">keyboard_arrow_left</mat-icon></div>
                    <div class="headline"><mat-icon (click)="nextArrow()">keyboard_arrow_right</mat-icon></div>
                </div>
            </div>


            <ng-container *ngIf="showSearchInput">
                <div class="d-flex justify_content_between align_items_center mgn-up-10 gap_10 search_filter_bg">
                    <input type="text" placeholder="Search by title..." [(ngModel)]="searchQuery"
                        (input)="onSearchChange()" class="search-input" />
                    <div class="headline"><mat-icon (click)="toggleFilter()">filter_list</mat-icon></div>
                </div>
                <div *ngIf="showFilterOptions">
                    <div class="d-flex justify_content_between align_item_center mgn-up-10">
                        <div>Filter</div>
                        <div class="cursor-pointer" (click)="resetFilters()">Reset</div>
                    </div>
                    <div class="mgn-up-10 d-flex justify_content_between align_item_center">
                        <div>Concept Type</div>
                        <div class="width_50">
                            <select class="mat-select">
                                <option>All</option>
                                <option>Text</option>
                            </select>
                        </div>
                    </div>
                    <div class="mgn-up-10 d-flex justify_content_between align_item_center">
                        <div>Period</div>
                        <div class="width_50">
                            <select (change)="onDateChange($event)" class="mat-select">
                                <option value="all">All Dates</option>
                                <option *ngFor="let date of uniqueDates" [value]="date">{{ date | date:'d MMMM y' }}
                                </option>
                            </select>

                        </div>
                    </div>
                </div>
            </ng-container>

            <div *ngIf="templateData && templateData.length===0 && searchListShowHide" class="fact-label">
                <div><mat-icon>edit</mat-icon></div>
                <div class="fs_25">No Fact Available</div>
            </div>
            <ng-container *ngIf="templateData && templateData.length>0 && searchListShowHide">
                <div>
                    <div class="mgn-up-20">
                        <ng-container *ngFor="let content of templateData;let i = index">
                            <div *ngIf="i === currentIndex">
                                <div class="concept">Concept</div>
                                <div class="mgn-up-10">
                                    <div class="d-flex justify_content_start gap_10">
                                        <div class="dot"></div>
                                        <div class="topic-name">{{content.topicName}}</div>
                                    </div>
                                </div>
                                <div class="concept mgn-up-10">Dimensions</div>
                                <div class="d-flex justify_content_start gap_10 align_item_start mgn-up-10">
                                    <div class="concept">Date</div>
                                    <div class="fw_400">
                                        <div>{{content['startDate'] | date:'d MMMM y'}} to {{content['startDate'] |
                                            date:'d MMMM y'}}</div>
                                    </div>
                                </div>

                                <div class="d-flex justify_content_start gap_10 align_item_start mgn-up-10  ">
                                    <div class="concept">Fact Value</div>
                                    <div class="fw_400">
                                        <div class="company-topic-name">{{content.mainContent}}</div>
                                    </div>
                                </div>

                                <div class="d-flex justify_content_start gap_10 align_item_start mgn-up-10  ">
                                    <div class="concept ">Accuracy</div>
                                    <div class="fw_400">
                                        <div>{{content.accuracy ? content.accuracy : 'n/a'}}</div>
                                    </div>
                                </div>

                                <div class="d-flex justify_content_start gap_10 align_item_start mgn-up-10  ">
                                    <div class="concept ">Change</div>
                                    <div class="fw_400">
                                        <div>{{content.chnage ? content.chnage : 'n/a'}}</div>
                                    </div>
                                </div>

                                <div class="d-flex justify_content_start gap_10 align_item_start mgn-up-10  ">
                                    <div class="concept ">Entity</div>
                                    <div class="fw_400">
                                        <div class="company-name">{{content.entity}}</div>
                                    </div>
                                </div>

                                <div class="d-flex justify_content_start gap_10 align_item_start mgn-up-10  ">
                                    <div class="concept ">Concept</div>
                                    <div class="fw_400 d-flex">
                                        <div class="company-topic-name">esrs:{{content.topicName}}</div>
                                        <div><mat-icon [cdkCopyToClipboard]="content.topicName"
                                                style="color: #0094ff;font-size: 16px;">file_copy</mat-icon></div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div class="d-flex justify_content_center align_item_center gap_10 mgn-up-10">
                        <div (click)="previous()"><mat-icon>keyboard_arrow_left</mat-icon></div>
                        <div>{{currentIndex+1 + ' of ' + templateData.length}}</div>
                        <div (click)="next()"><mat-icon>keyboard_arrow_right</mat-icon></div>
                    </div>

                </div>

                <ng-container>
                    <div *ngFor="let section of filterSections" class="mgn-up-20">
                        <div class="d-flex filter-label">
                            <mat-icon (click)="togglefilterSection(section)">
                                {{ section.show ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}
                            </mat-icon>
                            <div>{{ section.name }}</div>
                        </div>
                        <ng-container *ngIf="section.show">
                            <ng-container *ngIf="section.list && section.list.length === 0">
                                <div [ngClass]="{'expanded': section.show}">
                                    <div>{{ section.noIssueMessage }}</div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="section.list && section.list.length > 0">
                                <div *ngFor="let item of section.list">
                                    {{ item }}
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>

                </ng-container>
            </ng-container>

            <div *ngIf="templateData && templateData.length===0 && showOutlineList && !showSearchInput"
                class="fact-label">
                <div><mat-icon>view_headline</mat-icon></div>
                <div class="fs_25">Outline Unavailable</div>
            </div>
            <div *ngIf="templateData && templateData.length >0 && showOutlineList && !showSearchInput"
                class="fact-label">
                <ng-container *ngFor="let outline of templateData ;let i = index">
                    <div class="mgn-up-10 search-filter_data-bg d-flex justify_content_between align_items_center ">
                        <div>
                            <div class="searchTopicName cursor-pointer" (click)="navigateToSection(i)">
                                {{outline.topicName}}</div>
                        </div>
                    </div>
                </ng-container>
            </div>

            <div *ngIf="searchList && searchList.length===0 && showSearchInput && !showOutlineList && !searchListShowHide"
                class="fact-label">
                <div><mat-icon>search</mat-icon></div>
                <div class="fs_25">Search Unavailable</div>
            </div>
            <div *ngIf="searchList && searchList.length >0 && showSearchInput && !showOutlineList && !searchListShowHide"
                class="fact-label">
                <ng-container *ngFor="let search of searchList;let i = index">
                    <div
                        class="mgn-up-10 search-filter_data-bg d-flex justify_content_between align_items_center searchTopic_bg">
                        <div>
                            <div class="searchTopicName cursor-pointer" (click)="navigateToSection(i)">
                                {{search.topicName}}</div>
                            <div class="mgn-up-5 date_filter_search">{{search['startDate'] | date:'d MMMM y'}} to
                                {{search['startDate']
                                | date:'d MMMM y'}}</div>
                        </div>
                        <div>
                            <mat-icon (click)="openSearchFactList()"
                                style="color: #026dce;">subdirectory_arrow_left</mat-icon>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>


    </div>
</section>