import { AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Inject, NO_ERRORS_SCHEMA, OnInit, ViewChild } from '@angular/core';
import { TemplateViewerService } from './template-viewer.service';
import { MatList, MatListItem } from '@angular/material/list';
import { ActivatedRoute, NavigationExtras, Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatAccordion, MatExpansionModule, MatExpansionPanel, MatExpansionPanelDescription, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatRadioButton, MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { CapitalizeFirstPipeModule } from '../pipes/capitaliza.module';
import { projectMessageLocal } from './project-message';
import { SnackbarService } from './snackbar.service';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { IncompleteQuestionsDialogComponent } from '../incomplete-questions-dialog/incomplete-questions-dialog.component';
import { MatIcon } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFileExcel, faFileImage, faFilePdf, faFileWord, faCircle } from '@fortawesome/free-solid-svg-icons';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { CongratulationsComponent } from '../congratulations/congratulations.component';
import { HttpClient, HttpClientModule, HttpHandler } from '@angular/common/http';
import { SharedService } from '../shared.service';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';

@Component({
  selector: 'app-template-viewer',
  standalone: true,
  imports: [IncompleteQuestionsDialogComponent, CommonModule, RouterOutlet, MatListItem, MatExpansionPanelDescription, MatList, MatExpansionPanelTitle,
    MatExpansionModule, MatExpansionPanel, MatAccordion, MatRadioButton, MatRadioModule, MatCheckboxModule,
    MatCheckbox, MatCardModule, CapitalizeFirstPipeModule, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule,
    MatIcon, MatProgressSpinnerModule, MatTooltipModule, FontAwesomeModule, MatStepperModule, MatProgressBarModule,
    CongratulationsComponent, MatSelectModule, MatOptionModule, FormsModule,
    
 
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [TemplateViewerService], // Make sure TemplateViewerService is provided
  templateUrl: './template-viewer.component.html',
  styleUrls: ['./template-viewer.component.scss']
})
export class TemplateViewerComponent implements AfterViewChecked,OnInit ,AfterViewInit,AfterContentChecked{
  data: any;
  completionPercentage: number = 0;
  key: any;
  startTime!: Date;
  uploading: boolean = false;
  progressValue = 5;
  faFilePdf = faFilePdf;
  faFileWord = faFileWord;
  faFileExcel = faFileExcel;
  faFileImage = faFileImage;
  faCircleIcon = faCircle;
  showCongratulations: boolean = false;
  activeStep: number = 0;
  surveyStarted: boolean = false;
  phoneNumber: string = '+49 8022 18808886'
  selected = ''
  isPositive: boolean = false;
  isActual: boolean = false;
  filteredSection: any;
  @ViewChild('signaturePad', { static: false }) signaturePadElement!: ElementRef<HTMLCanvasElement>;
  private ctx!: CanvasRenderingContext2D;
  private drawing = false;




  constructor(public templateViewerService: TemplateViewerService, public sharedService: SharedService,
    private route: ActivatedRoute,private router:Router,
    private snackbarService: SnackbarService, private fb: FormBuilder,
    private dialog: MatDialog, public cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.key = params['key'];
    });
    this.startTime = new Date();
    this.loadTemplate(this.key);
  }
  startSurvey() {
    // this.surveyStarted = true;
    this.activeStep = 0;

    let queryParams = {
      key: this.key
    }
    let navigationExtras: NavigationExtras = {
      queryParams: queryParams
    };
    this.router.navigate(['template-question-answer'], navigationExtras)

  }

  previousStep() {
    if (this.activeStep > 0) {
      this.activeStep--;
    }
  }

  nextStep() {
    if (this.activeStep < (this.data?.sections.length - 1)) {
      this.activeStep++;
    }
  }

  selectStep(index: number) {
    console.log("Step number:", index);
    this.activeStep = index;
  }

  loadTemplate(id: any) {
    this.templateViewerService.getSmaSurvey(id).subscribe({
      next: (response: any) => {
        // Parse stringified options
        this.parseOptions(response);
        console.log(response);
        this.data = response;
        this.initializeOptions();
        this.updateCompletion();
        this.cd.detectChanges(); // Trigger change detection

        this.initializeCanvas();

      },
      error: (error: any) => {
        console.log(error)
      }
    });
  }

  // Method to parse stringified options
  parseOptions(response: any) {
    if (response && response.sections) {
      response.sections.forEach((section: any) => {
        section.questions.forEach((question: any) => {
          if (question.options) {
            question.options = question.options.map((optionString: string) => {
              try {
                return JSON.parse(optionString);
              } catch (e) {
                console.error('Failed to parse option string:', optionString);
                return null; // Or handle the error as needed
              }
            }).filter((option: any) => option !== null);
          }
        });
      });
    }
  }

  updateCompletion() {
    let totalQuestions = 0;
    let completedQuestions = 0;

    this.data.sections.forEach((section: any) => {
      section.questions.forEach((question: any) => {
        totalQuestions++;
        if (question.answer && question.answer.trim() !== '') {
          completedQuestions++;
        } else if (question.type === 'checkbox') {
          if (question.options.some((option: any) => option.checked)) {
            completedQuestions++;
          }
        } else if (question.type === 'radio') {
          if (question.selectedOption) {
            completedQuestions++;
          }
        }
      });
    });

    this.completionPercentage = (completedQuestions / totalQuestions) * 100;
  }


  updateSurvey() {
    const incompleteQuestions = this.validateSurvey();
    if (incompleteQuestions.length > 0) {
      this.dialog.open(IncompleteQuestionsDialogComponent, {
        height: "600px",
        data: { incompleteQuestions, submittedQuestions: [], confirmation: false },
        autoFocus: false, // Disable auto focus
      });
      return;
    }
    const submittedQuestions = this.getSubmittedQuestions();
    this.dialog.open(IncompleteQuestionsDialogComponent, {
      height: "600px",
      data: { incompleteQuestions: [], submittedQuestions, confirmation: true },
      autoFocus: false, // Disable auto focus
    }).afterClosed().subscribe(confirmed => {
      if (confirmed) {
        const surveyData = this.surveyDataForAPI(this.data);
        console.log(surveyData);
        this.templateViewerService.UpdateSurvey(surveyData).subscribe({
          next: (response: any) => {
            if (response) {
              console.log(response);
              this.showCongratulations = true;
              // let message: string = projectMessageLocal.SURVEY_ADDED
              // this.snackbarService.openSnackBar(message, { panelClass: 'snackbarnormal' });
              this.resetTimer(); // Reset the timer after successful submission
            }
          },
          error: (error: any) => {
            console.log(error);
            let errorMsg: string = projectMessageLocal.SURVEY_FAILED;
            this.snackbarService.openSnackBar(errorMsg, { panelClass: 'snackbarerror' })
          }
        });
      }
    });
  }

  validateSurvey(): string[] {
    const incompleteQuestions: string[] = [];
    this.data.sections.forEach((section: any) => {
      section.questions.forEach((question: any) => {
        if (question.required) {
          let answer;
          if (question.type === 'checkbox') {
            answer = question.options.some((option: any) => option.checked);
          } else if (question.type === 'radio') {
            answer = question.selectedOption;
          } else if (question.type === 'input' || question.type === 'textarea') {
            answer = question.answer;
          }
          if (!answer || (Array.isArray(answer) && answer.length === 0)) {
            incompleteQuestions.push(question.text);
          }
        }
      });
    });
    return incompleteQuestions;
  }

  getSubmittedQuestions(): any {
    const sections: any[] = [];

    this.data.sections.forEach((section: any) => {
      const sectionData: any = {
        title: section.title,
        description: section.description || '',
        questions: []
      };

      section.questions.forEach((question: any) => {
        const questionData: any = {
          text: question.text,
          description: question.description || '',
          type: question.type,
          options: [],
          answer: ''
        };

        if (question.type === 'checkbox') {
          questionData.options = question.options.map((option: any) => {
            if (typeof option.value === 'object') {
              return option.value.answer || JSON.stringify(option.value);
            }
            return option.value;
          });

          const selectedOptions = question.options
            .filter((option: any) => option.checked)
            .map((option: any) => {
              if (typeof option.value === 'object') {
                return option.value.answer || JSON.stringify(option.value);
              }
              return option.value;
            });

          if (selectedOptions.length) {
            questionData.answer = selectedOptions;
          }
        } else if (question.type === 'radio') {
          questionData.options = question.options.map((option: any) => option.answer);

          if (question.selectedOption) {
            questionData.answer = question.selectedOption;
          }
        } else if (question.type === 'input' || question.type === 'textarea') {
          if (question.answer) {
            questionData.answer = question.answer;
          }
        }
        if (question.fileUpload && question.uploadedFiles && question.uploadedFiles.length > 0) {
          questionData.uploadedFiles = question.uploadedFiles.map((file: any) => ({
            name: file.name,
            url: file.url
          }));
          questionData.fileUpload = question.fileUpload
        }
        sectionData.questions.push(questionData);
      });

      sections.push(sectionData);
    });

    return sections;
  }

  private surveyDataForAPI(data: any): any {
    let isComplete = true;
    const sections = data.sections.map((section: any) => ({
      title: section.title,
      description: section.description,
      questions: section.questions.map((question: any) => {
        let answer;
        if (question.type === 'checkbox') {
          answer = question.options.filter((option: any) =>
            option.checked).map((option: any) =>
              option.value);
        } else if (question.type === 'radio') {
          answer = question.selectedOption;
        } else if (question.type === 'input' || question.type === 'textarea') {
          answer = question.answer;
        }
        if (!answer || (Array.isArray(answer) && answer.length === 0)) {
          isComplete = false;
        }
        const uploadedFile = question.uploadedFiles ? question.uploadedFiles : null;
        return {
          description: question.description,
          text: question.text,
          type: question.type,
          options: question.type === 'checkbox' || question.type === 'radio' ? question.options : null,
          answer: answer,
          uploadedFile: uploadedFile // Add uploadedFile to the question object
        };
      })
    }))
    console.log(sections);
    const endTime = new Date();
    const duration = this.formatDuration(endTime.getTime() - this.startTime.getTime()); // Duration in timestamp format
    return {
      surveyId: data.id,
      ctxId: data.ctxId,
      userId: data.userId,
      title: data.title,
      description: data.description,
      image: data.image,
      questions: JSON.stringify(sections),
      status: isComplete ? 'COMPLETED' : 'INCOMPLETE',
      duration: duration // Include duration in the API data
    };
  }

  initializeOptions() {
    this.data.sections.forEach((section: any) => {
      section.questions.forEach((question: any) => {
        if (question.type === 'checkbox') {
          question.options = question.options.map((option: any) => ({
            value: option,
            checked: false
          }));
        }
        if (question.type === 'radio' && question.options.some((option: any) => option.answer === 'Yes' || option.answer === 'No')) {
          question.showFurtherOptions = false;
        }
      });
    });
  }

  onCheckboxChange(question: any, option: any) {
    option.checked = option.checked;
    this.updateCompletion();
    this.cd.detectChanges();
  }

  onRadioChange(question: any, selectedOption: string, sectionIndex: number, questionIndex: number) {
    console.log('question', question)
    console.log('selectedOption', selectedOption)
    question.selectedOption = selectedOption;
    this.isPositive = false;
    this.isActual = false;
    if (question.text === "Positive/Negative") {
      if (question.selectedOption === 'Positive') {
        const hasActualPotentialQuestion = this.data.sections.some((section: any) =>
          section.questions.some((question: any) => 
            question.text === "Actual/Potential" && question.selectedOption === "Actual"
          ));
        if(hasActualPotentialQuestion) {
          this.isActual = true;
          this.filteredSection = this.data.sections.filter((sections: any) =>
            !sections.title.includes("Irremediability") && !sections.title.includes("Likelyhood"));
            this.isPositive = true;
        } else {
          this.filteredSection = this.data.sections.filter((sections: any) =>
            !sections.title.includes("Irremediability"));
           this.isPositive = true;
        }
      } else {
        const hasActualPotentialQuestion = this.data.sections.some((section: any) =>
          section.questions.some((question: any) => 
            question.text === "Actual/Potential" && question.selectedOption === "Actual"
          ));
          if(hasActualPotentialQuestion) {
            this.isActual = true;
            this.filteredSection = this.data.sections.filter((sections: any) =>
              !sections.title.includes("Likelyhood"));
          }
      }
    } else if(question.text === "Actual/Potential") {
      if(question.selectedOption === 'Actual') {
        const hasPositiveNegativelQuestion = this.data.sections.some((section: any) =>
          section.questions.some((question: any) => 
            question.text === "Positive/Negative" && question.selectedOption === "Positive"
          ));
        if(hasPositiveNegativelQuestion) {
          this.isPositive = true;
          this.filteredSection = this.data.sections.filter((sections: any) =>
            !sections.title.includes("Likelyhood") && !sections.title.includes("Irremediability"));
            this.isActual = true;
        } else {
          this.filteredSection = this.data.sections.filter((sections: any) =>
            !sections.title.includes("Likelyhood"));
            this.isActual = true;
        }
      } else {
        const hasPositiveNegativeQuestion = this.data.sections.some((section: any) =>
          section.questions.some((question: any) => 
            question.text === "Positive/Negative" && question.selectedOption === "Positive"
          ));
          if(hasPositiveNegativeQuestion) {
            this.isPositive = true;
            this.filteredSection = this.data.sections.filter((sections: any) =>
              !sections.title.includes("Irremediability"));
          }
      }
    }
this.getGlobalQuestionIndex(sectionIndex, questionIndex);
    this.updateCompletion();
    if (selectedOption === 'Yes') {
      // question.showFurtherOptions = true;
    } else {
      // question.showFurtherOptions = false;
    }
  }

  getGlobalQuestionIndex(sectionIndex: number, questionIndex: number): number {
    let globalIndex = 0;
    for (let i = 0; i < sectionIndex; i++) {
      if(this.isPositive || this.isActual) {
        globalIndex += this.filteredSection[i].questions.length;
      } else {
        globalIndex += this.data.sections[i].questions.length;
      }
    }
    globalIndex += questionIndex;
    return globalIndex;
  }

  resetTimer() {
    this.startTime = new Date(); // Reset the start time for the next survey attempt
  }

  private formatDuration(durationInMilliseconds: number): string {
    const totalSeconds = Math.floor(durationInMilliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}h ${minutes}m ${seconds}s`;
  }

  onFileChange(event: any, quesAns: any): void {
    const files: File[] = Array.from(event.target.files);
    const allowedFileTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'png', 'jpg', 'jpeg'];
    const existingFiles = quesAns.uploadedFiles || [];
    // Check if the number of selected files plus existing files exceeds the limit
    if (existingFiles.length + files.length > 5) {
      this.snackbarService.openSnackBar('You can only upload a maximum of 5 files.', { panelClass: 'snackbarerror' });
      return;
    }

    // Check if the number of selected files exceeds the limit
    if (files.length > 5) {
      this.snackbarService.openSnackBar('You can only upload a maximum of 5 files.', { panelClass: 'snackbarerror' });
      return;
    }
    // Check if all files are of the allowed types
    const invalidFiles = files.filter(file => {
      const extension = file.name.split('.').pop()?.toLowerCase();
      return !allowedFileTypes.includes(extension!);
    });

    if (invalidFiles.length > 0) {
      this.snackbarService.openSnackBar('Only PDF, Word, Excel, and Image files can be uploaded.', { panelClass: 'snackbarerror' });
      return;
    }
    if (files.length > 0) {
      const formData: FormData = new FormData();
      files.forEach((file) => {
        formData.append('file', file);
      });
      quesAns.uploading = true;
      this.templateViewerService.fileUpload(formData).subscribe({
        next: (response: any) => {
          console.log('API Response:', response);
          const fileUrl: string[] = JSON.parse(response);
          // Assuming response is a single URL or similar
          if (fileUrl.length === files.length) {
            quesAns.uploadedFiles = files.map((file, index) => ({
              name: file.name,
              url: fileUrl[index]
            }));
          }
          quesAns.uploadedFiles = [...existingFiles, ...quesAns.uploadedFiles];
          quesAns.uploading = false;
          this.updateCompletion();
          this.snackbarService.openSnackBar('Files uploaded successfully.', { panelClass: 'snackbarnormal' });
        },
        error: (error) => {
          console.log('error', error);
          this.snackbarService.openSnackBar('File upload failed.', { panelClass: 'snackbarerror' });
          quesAns.uploading = false;
        }
      });
    }
  }



  removeFile(quesAns: any, index: number): void {
    quesAns.uploadedFiles.splice(index, 1);
    this.snackbarService.openSnackBar('File removed successfully.', { panelClass: 'snackbarnormal' });
    this.updateCompletion();
  }

  ngAfterViewChecked() {
    this.cd.detectChanges();
  }

  getPointerPosition(): string {
    return `${this.completionPercentage}%`;
  }

  getDropdownList(dropdownAns: any): any {
    console.log(dropdownAns);

    if (typeof dropdownAns !== 'string') {
        console.error('Invalid input: dropdownAns is not a string', dropdownAns);
        return [];
    }

    const answer: string = dropdownAns;
    let answerArray: { name: string }[];

    if (answer.includes('\n')) {
        answerArray = answer.split('\n').map(item => ({ name: item.trim() }));
    } else {
        answerArray = answer.split(',').map(item => ({ name: item.trim() }));
    }

    console.log('answerArray', answerArray);
    return answerArray;
}

  trackByFn(item: any) {
    return item.name; // or any unique identifier
  }

  posNegativeActionPerform(Event: any) {
    console.log('Event', Event.value)
    // const irremediabilityControls = [
    //     { name: 'irremediabilityResponse' },
    //     { name: 'irremediabilityRationale' },
    //     { name: 'irremediabilityDataSource' }
    // ];
    // if(Event.value==='Positive' || Event.value==='Uncertain' ){
    //     this.hideIrremediabilityResponse =true;
    //     this.sharedService.removeFormFieldControl(this.iro_form,irremediabilityControls);
    // }
    // else{
    //     this.hideIrremediabilityResponse =false;
    //     this.sharedService.addFormFieldControl(this.iro_form,irremediabilityControls)
    // }

  } 
  

  startDrawing(event: MouseEvent) {
    this.drawing = true;
    this.draw(event);
  }

  stopDrawing() {
    this.drawing = false;
    this.ctx.beginPath();
  }

  draw(event: MouseEvent) {
    if (!this.drawing) return;

    const canvas = this.signaturePadElement.nativeElement;
    this.ctx.lineWidth = 2;
    this.ctx.lineCap = 'round';
    this.ctx.strokeStyle = '#000';

    this.ctx.lineTo(event.clientX - canvas.offsetLeft, event.clientY - canvas.offsetTop);
    this.ctx.stroke();
    this.ctx.beginPath();
    this.ctx.moveTo(event.clientX - canvas.offsetLeft, event.clientY - canvas.offsetTop);
  }

  clearSignature() {
    const canvas = this.signaturePadElement.nativeElement;
    this.ctx.clearRect(0, 0, canvas.width, canvas.height);
  }

  saveSignature() {
    const canvas = this.signaturePadElement.nativeElement;
    const dataURL = canvas.toDataURL('image/png');
    console.log(dataURL);
    // You can send this dataURL to your server or use it as needed
  }
  initializeCanvas() {
    setTimeout(() => {
      if (this.signaturePadElement && this.signaturePadElement.nativeElement) {
        const canvas = this.signaturePadElement.nativeElement;
        this.ctx = canvas.getContext('2d')!;
        canvas.addEventListener('mousedown', this.startDrawing.bind(this));
        canvas.addEventListener('mouseup', this.stopDrawing.bind(this));
        canvas.addEventListener('mousemove', this.draw.bind(this));
      } else {
        console.error('Canvas element not found');
      }
    }, 0); // Delay to ensure the canvas element is rendered
  }
  ngAfterViewInit() {
    // Initialize the canvas here if it should be visible
    // this.initializeCanvas();
  }
  ngAfterContentChecked() {
    // Check if the canvas is available
    // if (this.signaturePadElement) {
    //   this.initializeCanvas();
    // }
  }

  // initializeCanvas() {
  //   const canvas = this.signaturePadElement?.nativeElement;

  //   if (!canvas) {
  //     console.error('Canvas element not found');
  //     return;
  //   }

  //   this.ctx = canvas.getContext('2d')!;
  //   // Add event listeners for drawing
  //   this.addCanvasEventListeners(canvas);
  // }

  addCanvasEventListeners(canvas: HTMLCanvasElement) {
    canvas.addEventListener('mousedown', this.startDrawing.bind(this));
    canvas.addEventListener('mouseup', this.stopDrawing.bind(this));
    canvas.addEventListener('mousemove', this.draw.bind(this));
    canvas.addEventListener('mouseout', this.stopDrawing.bind(this));
  }
  
  clearCanvasListeners(canvas: HTMLCanvasElement) {
    canvas.removeEventListener('mousedown', this.startDrawing.bind(this));
    canvas.removeEventListener('mouseup', this.stopDrawing.bind(this));
    canvas.removeEventListener('mousemove', this.draw.bind(this));
    canvas.removeEventListener('mouseout', this.stopDrawing.bind(this));
  }
  

  
}