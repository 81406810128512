import { Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Inject, NO_ERRORS_SCHEMA, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { SharedService } from '../shared.service';
import { TemplateViewerService } from '../template-viewer/template-viewer.service';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-tagging-report',
  standalone: true,
  imports: [CommonModule, RouterOutlet, MatIconModule, MatButtonModule, MatMenuModule, ClipboardModule, FormsModule, ReactiveFormsModule],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [TemplateViewerService], // Make sure TemplateViewerService is provided
  templateUrl: './tagging-report.component.html',
  styleUrls: ['./tagging-report.component.scss', '../common.scss']
})
export class TaggingReportComponent implements OnInit {

  public templateData: any[] = [];
  @ViewChildren('contentRefs') contentRefs!: QueryList<ElementRef>;
  @ViewChild('contentRefs', { static: false }) contentRefss!: ElementRef;

  visibilityStates: boolean[] | undefined;
  zoomLevel: string = 'scale(1)'; // Default zoom level
  zoomFactor: number = 1; // Base zoom factor
  factLabelFilter: string = 'Fact Properties';
  // filterContent:any[]=[];
  currentIndex: number = 0;
  showValidation: boolean = false;
  validationList: any[] = []; // Replace with your actual validation data
  filterSections = [
    { name: 'Validations', show: false, list: [], noIssueMessage: 'No validation issues found' },
    { name: 'References', show: false, list: [], noIssueMessage: 'No references available' },
    { name: 'Calculation', show: false, list: [], noIssueMessage: 'No calculations required' },
    { name: 'Footnotes', show: false, list: [], noIssueMessage: 'No footnotes provided' },
    { name: 'Sections', show: false, list: [], noIssueMessage: 'No sections to display' }
  ];

  searchFactList: any[] = []
  outlineList: any[] = [];
  showSearchInput: boolean = false;
  searchQuery: string = '';
  activeIcon: string = '';
  showOutlineList: boolean = false;
  searchListShowHide: boolean = true;
  searchList: any[] = [];


  reportResponse: any;
  environmentalImpacts: any[] = [];
  environmentalSubtopic: any[] = [];
  socialImpacts: any[] = [];
  governanceImpacts: any[] = [];
  environmentalRisks: any[] = [];
  socialRisks: any[] = [];
  governanceRisks: any[] = [];

  impactMateriality: any = [];
  noinMaterial: any = [];

  impactMaterialityRisk: any = [];
  noinMaterialFinanciual: any = [];
  currentIndexHover: number = -1; // Track the currently hovered or selected index
  uniqueDates: Date[] = [];
  showFilterOptions: boolean = false; // Control filter visibility
  selectedConceptType: string = 'All'; // Track selected concept type
  selectedDate: string = 'all'; // Track selected date
  topicData: any;

  constructor(private sharedService: SharedService, private templateService: TemplateViewerService) { }


  ngOnInit() {
    this.getTemplateContent();
    // this.getMenus();
    // this.getChartResult();
  }
  // getTemplateContent(){
  //   this.templateData = this.sharedService.generateContent();
  // this.filteredData = [...this.templateData]; // Initialize with all data
  //   console.log('templateData',this.templateData)
  //   this.filterContent=this.sharedService.generateContent()
  // }

  getTemplateContent() {
    this.templateService.getTopics(1, 1).subscribe({
      next: (response) => {
        this.templateData = response;
        this.searchList = [...this.templateData];
        console.log('topic' +this.templateData)
      },
      error: (error) => {
        console.error('Error fetching topics ' + error);
      }
    });


  }
  generateUniqueDates() {
    const datesSet = new Set<string>();
    this.templateData.forEach(item => {
      const dateStr = item.startDate.toDateString();
      datesSet.add(dateStr);
    });
    this.uniqueDates = Array.from(datesSet).map(dateStr => new Date(dateStr));
  }

  scrollToTopic(index: number) {
    const contentRefsArray = this.contentRefs.toArray();
    if (contentRefsArray[index]) {
      const element = contentRefsArray[index].nativeElement;
      element.scrollIntoView({ behavior: 'smooth' });

    }
  }

  zoomIn() {
    this.zoomFactor += 0.1; // Increase zoom factor
    this.updateZoom(); // Update the zoom level
  }

  zoomOut() {
    if (this.zoomFactor > 1) {
      this.zoomFactor -= 0.1; // Decrease zoom factor
      this.updateZoom(); // Update the zoom level
    }
  }

  updateZoom() {
    this.zoomLevel = `scale(${this.zoomFactor})`;
  }

  previous() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  next() {
    if (this.currentIndex < this.templateData.length - 1) {
      this.currentIndex++;
    }
  }
  togglefilterSection(section: any) {
    section.show = !section.show;
  }
  actionPerform(type: string) {
    this.activeIcon = type;
    if (type === 'outlineUnavailable') {
      this.showOutlineList = true;
      this.showSearchInput = false;
      this.searchListShowHide = false
    }
    else {
      this.showOutlineList = false;
      this.showSearchInput = true;
      this.searchListShowHide = false

    }
  }
  toggleSearch() {
    this.showSearchInput = !this.showSearchInput;
  }

  onSearchChange() {
    console.log('searchQuery', this.searchQuery);
    if (this.searchQuery) {
      const filteredList = this.templateData.filter(item =>
        item.topicName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
      console.log('filteredList', filteredList); // Log filtered results
      this.searchList = [...filteredList];
    } else {
      this.searchList = [...this.templateData];
    }
    console.log('searchList', this.searchList);
  }


  openSearchFactList() {
    this.searchListShowHide = true;

  }
  // navigateToSection(){
  //   if (this.contentRefss) {
  //     this.contentRefss.nativeElement.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }

  navigateToSection(index: number) {
    // Scroll to the content section corresponding to the clicked outline
    const elements = this.contentRefs.toArray();
    if (elements[index]) {
      elements[index].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  fetchChildItems(parentId: number, callback: () => void) {
    this.templateService.getChildMenuItems(environment.userId, environment.workspaceId, parentId).subscribe({
      next: (childResponse) => {
        console.log('Child items for parent ID ' + parentId, childResponse);
        this.templateService.selectedItem.push(...childResponse);
        const childWithHasChild = childResponse.filter((childItem: any) => childItem.hasChild === 'True');
        if (childWithHasChild.length === 0) {
          callback();
        } else {
          let pendingCalls = childWithHasChild.length;
          childWithHasChild.forEach((childItem: any) => {
            this.fetchChildItems(childItem.id, () => {
              pendingCalls--;
              if (pendingCalls === 0) {
                callback();
              }
            });
          });
        }
      },
      error: (error) => {
        console.error('Error fetching child items for parent ID ' + parentId, error);
      }
    });
  }

  getMenus() {
    this.templateService.selectedItem = [];
    this.templateService.getMenuItemsDynamic().subscribe({
      next: (response) => {
        console.log('Main response:', response);
        this.templateService.selectedItem.push(...response);
        const itemsWithHasChild = response.filter((item: any) => item.hasChild === 'True');
        if (itemsWithHasChild.length === 0) {
          this.callFinalAPI();
        } else {
          let pendingCalls = itemsWithHasChild.length;
          itemsWithHasChild.forEach((item: any) => {
            this.fetchChildItems(item.id, () => {
              pendingCalls--;
              if (pendingCalls === 0) {
                this.callFinalAPI();
              }
            });
          });
        }
      },
      error: (error) => {
        console.error('Error fetching main menu items', error);
      }
    });
  }

  callFinalAPI() {
    const selectedTagNames = this.templateService.selectedItem
      .filter((item: any) => item.hasChild === 'False' && item.isSelected === true)
      .map((item: any) => item.tagName);
    if (selectedTagNames.length > 0) {
      this.templateService.getTagsResult(environment.workspaceId, environment.userId, selectedTagNames).subscribe({
        next: (response) => {
          console.log('Result:', response);
          response.imaStats.forEach((item: any) => {
            if (item?.topicName) {
              if (item.topicName.startsWith('E')) {
                this.environmentalImpacts.push(item);
              } else if (item.topicName.startsWith('S')) {
                this.socialImpacts.push(item);
              } else if (item.topicName.startsWith('G')) {
                this.governanceImpacts.push(item);
              }
            }
          });
          response.fmaStats.forEach((item: any) => {
            if (item?.topicName) {
              if (item.topicName.startsWith('E')) {
                this.environmentalRisks.push(item);
              } else if (item.topicName.startsWith('S')) {
                this.socialRisks.push(item);
              } else if (item.topicName.startsWith('G')) {
                this.governanceRisks.push(item);
              }
            }
          });

        },
        error: (error) => {
          console.error('Error fetching tags result', error);
        }
      });
    }
  }

  getChartResult() {
    this.templateService.getBubbleChartResult(environment.workspaceId, environment.userId).subscribe({
      next: (response) => {
        console.log('Service response:', response);
        this.impactMateriality = response.filter((item: { overAllEvaluation: number; }) => item.overAllEvaluation < 2.5);
        this.noinMaterial = response.filter((item: { overAllEvaluation: number; }) => item.overAllEvaluation >= 2.5);

        this.impactMaterialityRisk = response.filter((item: { type: string; overAllEvaluation: number; }) => item.type === 'IMA' && item.overAllEvaluation < 2.5);
        this.noinMaterialFinanciual = response.filter((item: { type: string; overAllEvaluation: number; }) => item.type === 'FMA' && item.overAllEvaluation >= 2.5);
        console.log(this.impactMaterialityRisk);
        console.log(this.noinMaterialFinanciual)

      },
      error: (error) => {
        console.error('Service error:', error);
        // this.isLoading = false;
      }
    });
  }

  previousArrow() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.scrollToCurrent();
    }
  }

  nextArrow() {
    if (this.currentIndex < this.templateData.length - 1) {
      this.currentIndex++;
      this.scrollToCurrent();
    }
  }

  scrollToCurrent() {
    const elements = this.contentRefs.toArray();
    if (elements[this.currentIndex]) {
      elements[this.currentIndex].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
  onDateChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedDate = selectElement.value;

    if (selectedDate === 'all') {
      this.searchList = [...this.templateData]; // Reset to all data
    } else {
      const date = new Date(selectedDate);
      this.searchList = this.templateData.filter(item =>
        item.startDate.toDateString() === date.toDateString()
      );
    }
    console.log('Filtered searchList', this.searchList);
  }

  toggleFilter() {
    this.showFilterOptions = !this.showFilterOptions; // Toggle visibility
  }
  resetFilters() {
    this.selectedConceptType = 'All'; // Reset selected concept type
    this.selectedDate = 'all'; // Reset selected date
    this.showFilterOptions = false; // Hide filter options
    this.searchList = [...this.templateData]; // Restore original data
  }

}